.container {
  display: inline-flex;
  align-items: center;
  padding: 6px 4px 6px 7px;
}

.text {
  margin-left: 6px;
  font-size: 14px;
  color: #0950b4;
}
