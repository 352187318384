.container {
  margin: 24px auto 0;
  padding: 10px;
  width: 400px;
  background-color: #f8e0e0;
  border: 1px solid #e02020;
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.4;
  text-align: justify;
  color: #e02020;
}
