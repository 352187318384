.container {
  display: flex;
  align-items: center;
  margin-top: 8px;

  > *:nth-child(n) {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}
