.titleArea {
  position: relative;
  display: flex;
  padding: 15px 0;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -13px;
    width: 4px;
    height: 26px;
    background-color: #007efc;
    border-radius: 2px;
  }
}

.title {
  font-size: 24px;
  color: #000111;
  line-height: 1.3;
  letter-spacing: 0.05em;
  margin-left: 10px;
}
