.container {
  margin-top: 80px;
  text-align: center;
}

.message {
  margin-bottom: 40px;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0.05em;
  color: #000111;
}
