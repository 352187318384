.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 56px;
}

.input {
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  font-size: 16px;
  line-height: 1.4;
  color: #000111;
  resize: vertical;

  border: 1px solid #b5b9c2;
  border-radius: 4px;
  padding: 7px;

  field-sizing: content;
  max-height: 300px;

  &:focus {
    box-shadow:
      0 0 0 2px white,
      0 0 0 3px #007efc;
  }

  &:not(:focus):hover {
    color: #0950b4;
    cursor: pointer;
  }
}
