.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  background-color: white;
  border-bottom: 1px solid #e6e7f2;
}

.careRecipient {
  display: flex;
  align-items: center;
}

.project {
  display: flex;
  align-items: center;
}
