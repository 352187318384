.container {
  padding: 24px 24px 136px;
  overflow-y: auto;
  height: calc(100vh - 15px);
  background: #ffffff;

  &::-webkit-scrollbar {
    width: 20px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: lightgray;
  }
}

.backButtonArea {
  display: flex;
}

.backButton {
  align-items: center;
  font-size: 14px;
  line-height: 1.4;

  * {
    fill: #000111;
  }
}
