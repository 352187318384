.container {
  display: flex;
  align-items: flex-start;
  width: 500px;
}

.row {
  display: flex;
  border-top: 1px solid #e6e7f2;
  width: 100%;
}

.label {
  position: relative;
  width: 50%;
  padding: 22px 24px;
  font-size: 14px;
  line-height: 20px; // 行の高さを20pxにしないと.fieldと高さが一致しないので絶対値で指定
  color: #000111;
}

.labelText {
  position: relative;
  width: auto;
  padding: 22px 16px;
  font-size: 14px;
  line-height: 20px; // 行の高さを20pxにしないと.fieldと高さが一致しないので絶対値で指定
  color: #000111;
}

.labelTextPaddingNo {
  position: relative;
  width: auto;
  padding-top: 4px;
  font-size: 14px;
  line-height: 20px; // 行の高さを20pxにしないと.fieldと高さが一致しないので絶対値で指定
  color: #000111;
}

.field {
  display: flex;
  align-items: center;
  width: 50%;
  padding: 12px 16px;
  justify-content: flex-end;
}

.saveIndicator {
  position: absolute;
  right: 4px;
  top: 16.5px;
}

.horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  > * {
    margin-right: 19px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.vertical {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > * {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
