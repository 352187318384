@import '../../components/decoratedBox';

.itemIndex {
  @include decoratedBox();

  padding: 16px 16px 20px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
}

.itemIndexShikkanTitle {
  font-size: 16px;
  line-height: 1.4;
  color: #000111;
}

.itemIndexAdviceTitle {
  font-size: 16px;
  line-height: 1.4;
  color: #000111;
  margin-top: 16px;
}

.itemIndexContents {
  margin: 8px 0 0 8px;
  font-size: 14px;
  line-height: 1.4;
  color: #000111;
}

.link {
  &:active,
  &:hover {
    color: #0950b4;
  }
  &:hover {
    text-decoration: underline;
  }
}

.active {
  color: #0950b4;
}
