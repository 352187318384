@import 'tableTwoVariables';

.container {
  background-color: #e6e7f2;
}

.header {
  position: fixed;
  background-color: #e6e7f2;
  width: 100%;
  z-index: $z-index_tableTwoContainerHeader;
}
