.title {
  position: relative;
  margin: 16px 0;
  padding-left: 16px;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0.05em;
  color: #000111;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 24px;
    background-color: #007efc;
    border-radius: 2px;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 4px;
}

.menu {
  margin-bottom: 17px;
  display: flex;
  align-items: center;
}

.lastEditedAt {
  margin-right: 16px;
  font-size: 13px;
  line-height: 1.2;
  color: #7b7c88;
}

.lastEditedAtRsLogin {
  font-size: 13px;
  line-height: 1.2;
  color: #7b7c88;
}
