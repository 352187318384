.title {
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 0.05em;
  color: #000111;
  margin-bottom: 24px;
  text-align: center;
}

.card {
  padding: 40px 24px;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;

  width: 548px;

  /* white/01 */
  background: #ffffff;

  /* BlackShadow */
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
  border-radius: 4px;
}

.contents {
  margin-left: auto;
  margin-right: auto;
  width: 280px;
}

.label {
  color: #7b7c88;
  margin-bottom: 4px;
  font-size: 14px;
}

.field {
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 16px;
}

.field:not(:last-child) {
  @extend .field;
  border-bottom: #e6e7f2 solid 1px;
}

.textButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  margin-right: auto;
  width: 200px;
}

.checked {
  margin-left: 19px;
  margin-right: 19px;
  * {
    fill: #007efc;
  }
}
.notification {
  position: absolute;
  right: 20px;
  bottom: 102px;
  background: #ffffff;
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
  border-radius: 3px;
  width: 356px;
  height: 56px;
  display: flex;
  align-items: center;
}

.close {
  margin-left: auto;
  margin-right: 22px;
  width: 24px;
  * {
    fill: #7b7c88;
  }
}
