.logo {
  margin-right: 4px;
}

.button {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.4;
  color: #000111;
  margin-right: 16px;

  &:hover,
  &:active,
  &focus {
    color: #0950b4;
    text-decoration: underline;
  }
}
