.container {
  padding: 40px;
  position: relative;
}

.adminNotificationLink {
  position: absolute;
  top: 15px;
  right: 40px;
  border-radius: 4px;
}
