@import 'tableThree';
@import '../tableThreeVariables';

.container {
  margin: 0 24px;
  width: 97%;
}

.tableHeader {
  display: flex;
  margin-top: 20px;
  background-color: #f6f7f8;
  border-top-left-radius: $borderRadius;
  border-top-right-radius: $borderRadius;
}

.tableBody {
}

.time {
  flex: 2;
  display: flex;
  border-right: $border;
  border-bottom: $border;
}

.groupYoubi {
  flex: 7;
  display: flex;
  border-bottom: $border;
}

.sunday {
  margin-left: 100px;
}

.activity {
  flex: 3;
  border-bottom: $border;
}

.content {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  padding: 11px 0 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;
  color: #000111;
  text-align: center;
  border-right: $border;
}

.activityContent {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 11px 0 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;
  color: #000111;
  text-align: center;
}
