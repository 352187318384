.field {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  .input {
    position: absolute;
    appearance: none;
    opacity: 0;
  }

  .display {
    display: inline-block;
    position: relative;
    width: 48px;
    height: 24px;
    border-radius: 12px;

    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      left: 2px;
      top: 2px;
      width: 20px;
      height: 20px;
      background-color: white;
      border-radius: 10px;
      transition: all 150ms;
    }
  }

  .label {
    display: inline-block;
    margin-right: 8px;
    font-size: 14px;
    line-height: 1.4;
  }

  &:not(.checked):not(.disabled) {
    .display {
      background-color: #b5b9c2;

      &::after {
        transform: translateX(0);
      }
    }

    .label {
      color: #000111;
    }

    &:hover,
    &:active {
      .display {
        background-color: #7b7c88;
      }

      .label {
        color: #0950b4;
      }
    }
  }

  &.checked:not(.disabled) {
    .display {
      background-color: #007efc;

      &::after {
        transform: translateX(24px);
      }
    }

    .label {
      color: #000111;
    }

    &:hover,
    &:active {
      .display {
        background-color: #0950b4;
      }

      .label {
        color: #0950b4;
      }
    }
  }

  // TODO: disabled時の見た目は不十分
  &:not(.checked).disabled {
    .display {
      background-color: white;

      &::after {
        transform: translateX(0);
      }
    }

    .label {
      color: #b5b9c2;
    }
  }

  &.checked.disabled {
    .display {
      background-color: #d9ecff;

      &::after {
        transform: translateX(24px);
      }
    }

    .label {
      color: #b5b9c2;
    }
  }
}
