@import 'tableTwo';

.container {
  @include container();
}

.textCell {
  @include cell(widthRate($needTextWidth, $needAllWidth));
}

.rest {
  @include rest(widthRate($needRestWidth, $needAllWidth));
}
