.container {
  &:first-child {
    .title {
      padding-top: 8px;
      /* カテゴリの直下にサブカテゴリがある場合、23px空けたいので、Categoryの持つPadding15pxに8pxを合わせている */
    }
  }
}

.title {
  font-weight: bold;
  font-size: 16px;
  line-height: 1.4;
  color: #000111;
  padding: 20px 0 19px 0;
}
