.container {
  position: relative;
  width: calc(33% - 18px);
  background: #ffffff;
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
  border-radius: 4px;
  padding: 16px;
  margin-right: 28px;
  margin-top: 28px;
  cursor: pointer;
  height: 508px;
  min-width: 266px;

  &:nth-of-type(3n) {
    margin-right: 0;
  }

  &:hover .establishmentName {
    color: #0950b4;
    text-decoration: underline;
  }
}

.establishmentName {
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0.05em;
  color: #000111;
  min-height: 54px;
  vertical-align: middle;
  justify-content: space-between;
}

.buttonArea {
  position: absolute;
  left: 50%;
  bottom: 16px;
  transform: translateX(-50%);
}

.thumbnailsArea {
  margin-top: 8px;
}
