.container {
  padding: 106px 348px 300px 348px;
  background: #ffffff;
}

.mainMessage {
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0.05em;
  color: #000111;
  display: flex;
  justify-content: center;
}

.subMessageArea {
  margin-top: 16px;
  font-size: 16px;
  line-height: 1.4;
  color: #000111;
  display: flex;
  justify-content: center;
  text-align: center;
  a {
    color: #007efc;
  }
}

.buttonArea {
  margin-top: 24px;
  display: flex;
  justify-content: center;
}
