@import 'src/app/view/components/decoratedBox';

.container {
  width: 345px;
}

.indexContainer {
  @include decoratedBox;

  padding: 16px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
}

.link {
  display: block;
  margin-top: 8px;
  font-size: 14px;
  line-height: 1.4;
  color: #000111;

  &:first-child {
    margin-top: 0;
  }
  &:active,
  &:hover {
    color: #0950b4;
    text-decoration: underline;
  }
}

.active {
  color: #0950b4;
}

.buttonArea {
  margin-top: 24px;
  display: flex;
  justify-content: center;
}
