.field {
  border-radius: 4px;
  transition: all 150ms;

  .focus {
    display: inline-flex;
    align-items: center;
    padding: 0 7px;
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    border-color: #b5b9c2;
  }

  .danger {
    border-color: #ff0000 !important;
  }

  .input {
    display: inline-block;
    width: 100%;
    flex-grow: 1;
    padding: 10px 0;
    font-size: 16px;
    line-height: 1.2;
    color: #000111;
    resize: vertical;
  }

  &:not([disabled]) {
    background-color: white;

    .leading,
    .trailing {
      * {
        fill: #7b7c88;
      }
    }

    &:focus-within {
      .focus {
        box-shadow:
          0 0 0 2px white,
          0 0 0 3px #007efc;
      }
    }
  }

  &[disabled] {
    background-color: #e0e0e0;

    .focus {
    }

    .leading,
    .trailing {
      * {
        fill: #e0e0e0;
      }
    }
  }

  .label {
    position: relative;
    &:hover {
      .popup {
        display: inline-block;
      }
    }
  }
}

.popup {
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, -4px);
  display: none;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
  font-size: 14px;
  line-height: 1.4;
  color: black;
  white-space: nowrap;
  background-color: white;
}
