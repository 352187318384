.container {
  display: flex;
  justify-content: center;
}

.button {
  background-color: white;
  color: #0950b4;
  margin-left: 8px;
  width: 48px;
  height: 48px;
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:not(.disabled) {
    &:hover {
      background-color: #007efc;
      color: white;

      svg {
        * {
          fill: white;
        }
      }
    }
  }

  &:first-child {
    margin-left: 0;
  }

  a {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}

.moveButton {
  @extend .button;
  width: 80px;
  height: 48px;
  svg {
    * {
      fill: #0950b4;
    }
  }
}

.previousButton {
  @extend .moveButton;
  svg {
    margin-right: 10px;
  }
}

.nextButton {
  @extend .moveButton;
  svg {
    margin-left: 10px;
  }
}

.disabled {
  background-color: transparent;
  color: #b5b9c2;
  cursor: auto;
  box-shadow: none;
  svg {
    * {
      fill: #b5b9c2;
    }
  }
}

.activePage {
  background-color: #007efc;
  color: white;
}

.break {
  @extend .button;
  background-color: transparent;
  box-shadow: none;
  cursor: auto;
  a {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    pointer-events: none;
  }
}
