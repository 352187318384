@function focus-shadow($borderColor, $gapColor) {
  @return 0 0 0 2px $gapColor, 0 0 0 3px $borderColor;
}

// normal
$normalForegroundColor: #0950b4;

$normalBackground: white;

// active
$activeForegroundColor: white;

$activeBackgroundColor: linear-gradient(90deg, #0950b4 100%, #007efc 100%);

// disabled
$disabledForegroundColor: #d9ecff;

$disabledBackgroundColor: white;

$disabledShadow: none;

.button {
  border-radius: 50px;

  .focus {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    width: 100%;
    border-radius: 50px;
  }

  .leading,
  .trailing {
    display: inline-flex;
    align-items: center;
  }

  .leading {
    margin-right: 4px;
  }

  .trailing {
    margin-left: 4px;
  }

  .text {
    display: inline-block;
    padding: 12px 0;
    font-size: 16px;
    line-height: 1;
    letter-spacing: 0.05em;
  }

  &:not([disabled]) {
    background: $normalBackground;
    color: $normalForegroundColor;
    border: 1px solid #0950b4;

    .leading,
    .trailing {
      * {
        fill: $normalForegroundColor;
      }
    }

    &:hover,
    &:active {
      background: $activeBackgroundColor;
      color: $activeForegroundColor;

      .leading,
      .trailing {
        * {
          fill: $activeForegroundColor;
        }
      }
    }

    &:focus-visible {
      .focus {
      }

      &:hover,
      &:active {
        .focus {
        }
      }
    }
  }

  &[disabled] {
    background-color: $disabledBackgroundColor;
    border: 1px solid #d9ecff;
    color: $disabledForegroundColor;

    .leading,
    .trailing {
      * {
        fill: $disabledForegroundColor;
      }
    }
  }
}
