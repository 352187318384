@font-face {
  font-family: 'Noto Sans CJK JP Subset';
  font-style: normal;
  font-weight: 400;
  src:
    local('Noto Sans CJK JP'),
    local('NotoSansCJKjp-Regular'),
    local('NotoSansJP-Regular'),
    url('./font/NotoSansCJKjp-Regular.woff2') format('woff2'),
    url('./font/NotoSansCJKjp-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans CJK JP Subset';
  font-style: normal;
  font-weight: 700;
  src:
    local('NotoSansCJKjp-Bold'),
    local('NotoSansJP-Bold'),
    url('./font/NotoSansCJKjp-Bold.woff2') format('woff2'),
    url('./font/NotoSansCJKjp-Bold.woff') format('woff');
  font-display: swap;
}

body {
  font-family: 'Noto Sans', 'Noto Sans CJK JP', 'Noto Sans CJK JP Subset',
    sans-serif;
}
