.container {
  margin-top: 8px;
}

.row {
  display: flex;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  > *:nth-child(n) {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.cell {
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.4;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }
}

.active {
  background-color: #007efc;
  color: #ffffff;
}

.inactive {
  background-color: #e6e7f2;
  color: #b5b9c2;
}
