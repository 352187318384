.item {
  margin-top: 4px;
}

.checkIcon {
  vertical-align: middle;
}

.text {
  padding-left: 1%;
  font-size: 14px;
  color: #7b7c88;
}

.checkedText {
  padding-left: 1%;
  font-size: 14px;
  color: #0950b4;
}
