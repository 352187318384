.row {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  background-color: white;
  position: relative;
  border-bottom: solid 1px #e6e7f2;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
  }
}

.information {
  width: 85%;
}

.publishedDateArea {
  font-size: 14px;
  line-height: 1.4;
  color: #000111;
}

.notificationTitle {
  font-size: 16px;
  line-height: 1.4;
  color: #0950b4;
  font-weight: bold;
  margin-top: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.buttonArea {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 15%;
  .deleteButton {
    margin-left: 8px;
  }
}
