.container {
  cursor: pointer;
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}
