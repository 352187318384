.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 24px;
}

.logoAndLinks {
  display: flex;
  align-items: center;
}

.nav {
  display: flex;
  margin-left: 24px;

  li {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.link {
  font-size: 14px;
  line-height: 1.4;
  color: #000111;

  &:hover,
  &:active,
  &:focus {
    color: #0950b4;
    text-decoration: underline;
  }
}

.copyright {
  font-size: 13px;
  line-height: 1.2;
  color: #7b7c88;
}
