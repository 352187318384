@import 'tableThree';

.content {
  display: flex;
  flex: 1;
}

.display {
  flex: 1;
  display: block;
  font-size: 14px;
  line-height: 1.4;
  color: #000111;
  text-align: center;
  @include cell(widthRate($longTermGoalPeriodWidth, $longTermGoalAllWidth));
}

.schduleBox {
  position: absolute;
  height: 100%;
  width: 100%;
  &:hover {
    .popup {
      display: inline-block;
      z-index: 999;
    }
  }
  &:active {
    .popup {
      display: none;
    }
  }
}

.schduleBoxContainer {
  position: absolute;
  display: block;
  box-sizing: border-box;
  border: solid white 0.5px;
  border-radius: 4px;
  &:hover {
    .popup {
      display: inline-block;
      z-index: 999;
    }
  }
  &:active {
    .popup {
      display: none;
    }
  }
}

.period {
  background-color: #007efc;
  cursor: move;
  height: calc(100% - 7px);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  overflow: hidden;
  &:hover {
    color: #000111;
  }
}

.dragBar {
  height: 7px;
  cursor: row-resize;
  background-color: #007efc;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.label {
  font-size: 14px;
  padding-left: 4px;
  color: white;
}

.popup {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, -4px);
  display: none;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
  font-size: 14px;
  line-height: 1.4;
  color: black;
  white-space: nowrap;
  background-color: white;
}
