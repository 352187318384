.container {
  padding: 40px 24px;
  margin: auto;

  width: 350px;
  height: 100%;
  max-height: 490px;

  /* white/01 */
  background: #ffffff;

  /* BlackShadow */
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
  border-radius: 4px;

  position: relative;

  overflow-y: auto;
}

.checkArea {
  display: flex;
  margin-bottom: 20px;
}

.textArea {
  margin: 20px 20px;
}

.description {
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 0.05em;
  color: #000111;
  margin-bottom: 24px;
  text-align: center;
}

.title {
  font-size: 18px;
  line-height: 1.3;
  letter-spacing: 0.05em;
  color: #000111;
  margin-bottom: 4px;
}

.buttonArea {
  margin-top: 30px;
  text-align: center;
}

.closeIcon {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 24px;
  height: 24px;
  text-align: center;

  * {
    fill: #7b7c88;
  }
}

.errorMessage {
  color: red;
}
