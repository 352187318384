.container {
  display: flex;
  align-items: flex-start;
}

.sheet {
  flex-grow: 1;
}

.index {
  flex-shrink: 0;
  margin-left: 16px;

  .hidden {
    height: 0;
    overflow-y: hidden;
    visibility: hidden;
  }
}

.section {
  margin-bottom: 32px;
}
