@use 'sass:math';

$borderWidth: 2px;

$border: $borderWidth solid #e6e7f2;

$borderRadius: 4px;

// need with

$needAllWidth: 1392px;

$needTextWidth: 162px;

$needRestWidth: $needAllWidth - $needTextWidth;

// long term goal width

$longTermGoalAllWidth: $needRestWidth;

$longTermGoalTextWidth: 174px;

$longTermGoalPeriodWidth: 101px;

$longTermGoalRestWidth: $longTermGoalAllWidth -
  ($longTermGoalTextWidth + $longTermGoalPeriodWidth);

// short term goal width

$shortTermGoalAllWidth: $longTermGoalRestWidth;

$shortTermGoalTextWidth: 174px;

$shortTermGoalPeriodWidth: 101px;

$shortTermGoalRestWidth: $shortTermGoalAllWidth -
  ($shortTermGoalTextWidth + $shortTermGoalPeriodWidth);

// support service width

$supportServiceAllWidth: $shortTermGoalRestWidth;

$supportServiceTextWidth: 150px;

$supportServiceRestWidth: $supportServiceAllWidth - $supportServiceTextWidth;

// support service type width

$supportServiceTypeAllWidth: $supportServiceRestWidth;

$supportServiceTypeInsuranceBenefitWidth: 50px;

$supportServiceTypeTextWidth: 150px;

$supportServiceTypeEstablishmentWidth: 130px;

$supportServiceTypeFrequencyWidth: 101px;

$supportServiceTypePeriodWidth: 99px;

// header width

$allWidth: $needAllWidth;

$goalWidth: 550px;

$assistanceContentWidth: $allWidth - ($needTextWidth + $goalWidth);

// functions

@function widthRate($width, $allWidth) {
  @return math.div($width, $allWidth) * 100%;
}

// mixins

@mixin container() {
  flex-grow: 1;
  display: flex;
}

@mixin cell($width) {
  width: $width;
  background-color: white;
  border-right: $border;
  border-bottom: $border;

  &:hover {
    background-color: #f7fbff;
  }
}

@mixin rest($width) {
  display: flex;
  flex-direction: column;
  width: $width;
}
