.container {
  display: flex;
  justify-content: space-between;
  margin: 0 24px;
  padding: 16px 0;
  padding-bottom: 220px;

  .save {
    display: flex;
  }
}

.messages {
  > li {
    margin-top: 8px;
    font-size: 13px;
    line-height: 1.2;
    color: #7b7c88;

    &:first-child {
      margin-top: 0;
    }
  }
}
