@import '../root';

.globalHeader {
  z-index: $z-index_global_header;
  top: 0;
  width: 100%;
}

.projectHeader {
  z-index: $z-index_project_header;
  width: 100%;
}

.projectSheetTabBar {
  position: fixed;
  z-index: 1;
  display: inline-block;
  margin: 16px 0 0 50%;
  transform: translateX(-50%);
  bottom: 50px;
}
