// normal
$normalForegroundColor: #007efc;

$normalBackground: white;

// active
$activeForegroundColor: white;

$activeBackgroundColor: linear-gradient(90deg, #0950b4 100%, #007efc 100%);

// disabled
$disabledForegroundColor: #4ca5fd;

$disabledBackgroundColor: white;

.button {
  border-radius: 50px;
  width: 256px;

  .focus {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    width: 100%;
    border-radius: 50px;
  }

  .leading {
    display: inline-flex;
    align-items: center;
    margin-right: 4px;
    * {
      fill: $activeForegroundColor;
    }
  }

  .text {
    display: inline-block;
    padding: 12px 0;
    font-size: 16px;
    line-height: 1;
    letter-spacing: 0.05em;
  }

  &:not([disabled]) {
    background: $activeBackgroundColor;
    color: $activeForegroundColor;
  }
}
