.container {
  margin-top: 16px;
}

.title {
  font-size: 13px;
  line-height: 1.2;
  color: #7b7c88;
  margin-top: 8px;
  margin-bottom: 2px;

  &:first-child {
    margin-top: 0;
  }
}

.content {
  font-size: 14px;
  line-height: 1.4;
  color: #000111;
}

.tel {
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 0.05em;
  color: #000111;
}
