.containerClose {
  margin-top: 12px;
  &:first-child {
    margin-top: 0;
  }
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
  border-radius: 4px;
  background-color: white;
  img {
    transition: transform 0.5s;
  }
}

.containerOpen {
  @extend .containerClose;

  img {
    transform: rotate(-180deg);
    transition: transform 0.5s;
  }
}
