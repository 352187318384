.container {
  background-color: #f7fbff;
  padding: 16px;
  margin-top: 32px;
}

.conditionArea {
  margin-top: 16px;
}

.title {
  font-size: 14px;
  line-height: 1.4;
  color: #0950b4;
  &:last-child {
    margin-top: 16px;
  }
}

.checkboxArea {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  > * {
    margin-top: 6.5px;
    margin-right: 19px;
  }
}

.commonConditionArea {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;

  label {
    margin-right: 11px;
  }
}

.commonGroup {
  display: flex;
}

.rangeSelector {
  width: 136px;
  margin-right: 19px;
}

.keyword {
  margin-left: 8px;
}

.closeIcon {
  margin-right: 8px;
}
