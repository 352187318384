@import 'tableThree';
@import '../tableThreeVariables';

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  z-index: 0;
  border-bottom: $border;
}

.field {
  border-radius: 4px;
  transition: all 150ms;
  flex: 1;

  .focus {
    display: inline-flex;
    align-items: center;
    padding: 0 7px;
    width: 100%;
  }

  .danger {
    border-color: #ff0000 !important;
  }

  .input {
    display: inline-block;
    width: 100%;
    flex-grow: 1;
    font-size: 16px;
    line-height: 1.2;
    color: #000111;
    resize: vertical;
    z-index: 0;
  }

  &:not([disabled]) {
    background-color: white;

    .leading,
    .trailing {
      * {
        fill: #7b7c88;
      }
    }

    &:focus-within {
      z-index: 1;
      .focus {
        box-shadow:
          0 0 0 2px white,
          0 0 0 3px #007efc;
      }
    }
  }

  &[disabled] {
    background-color: #e0e0e0;

    .focus {
    }

    .leading,
    .trailing {
      * {
        fill: #e0e0e0;
      }
    }
  }
}
