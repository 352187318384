.scheduleContents {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
}

.week {
  display: flex;
  flex: 1;
}

.box {
  width: 88%;
  position: absolute;
}
