@import '../../components/decoratedBox';
@import '../../tabletwo/tableTwoVariables';

.container {
  @include decoratedBox(absolute);

  z-index: $z-index_tableTwoHeader + 1;
  left: calc(100% + 2px);
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 606px;
  padding: 16px 16px 20px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
}

.title {
  font-size: 20px;
  line-height: 140%;
  letter-spacing: 0.05em;
  color: #0950b4;
}

.suggestion {
  margin-top: 12px;
  font-size: 14px;
  line-height: 1.4;
  color: #000111;
  &:hover {
    color: #0950b4;
  }
}

.error {
  margin-top: 12px;
  font-size: 14px;
  line-height: 1.4;
  color: #000111;
}

.errorShow {
  display: inline-block;
  margin-top: 16px;
  font-size: 13px;
  line-height: 1.2;
  color: #7b7c88;
}

.errorDetail {
  margin-top: 24px;
  font-size: 16px;
  line-height: 1.4;
  color: #000111;
}
