.container {
  max-width: 448px;
  width: 100%;
  position: relative;
}

.slider1 {
  height: 248px;
}

.slider2 {
  margin-top: 3px;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mainImage {
  height: 248px;
}

.sliderImage {
  height: 130px;
}

.nextButton {
  position: absolute;
  right: 4px;
  top: 100px;
}

.previousButton {
  position: absolute;
  left: 4px;
  top: 100px;
}
