$safeColor: #007efc;

$dangerColor: #ff0000;

$scope_z-index: 1;

.container {
  display: flex;
  justify-content: space-between;
}

// button

.button {
  position: relative;
  margin-right: 4px;

  &:last-child {
    margin-right: 0;
  }

  svg {
    vertical-align: bottom;
  }

  &[disabled] {
    svg * {
      fill: #b5b9c2;
    }
  }
}

.buttonAdd,
.buttonShiftUp,
.buttonShiftDown {
  &:not([disabled]) {
    &:hover {
      svg * {
        fill: $safeColor;
      }
    }
  }
}

.buttonRemove {
  &:not([disabled]) {
    &:hover {
      svg * {
        fill: $dangerColor;
      }
    }
  }
}

// tooltip

.tooltip {
  position: absolute;
  z-index: $scope_z-index + 1;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, -4px);
  display: none;
  padding: 4px;
  border-radius: 4px;
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
  font-size: 14px;
  line-height: 1.4;
  color: white;
  white-space: nowrap;
}

.button:not([disabled]) {
  &:hover {
    .tooltip {
      display: inline-block;
    }
  }
}

.buttonAdd,
.buttonShiftUp,
.buttonShiftDown {
  .tooltip {
    background-color: $safeColor;
  }
}

.buttonRemove {
  .tooltip {
    background-color: $dangerColor;
  }
}

.button {
  &:hover {
    .iconText {
      display: inline-block;
    }

    &:disabled {
      .iconText {
        display: none;
      }
    }
  }
}

// scope

.boxScope {
  display: none;
  position: absolute;
  border: 2px solid $safeColor;
  pointer-events: none;
  z-index: $scope_z-index;

  &.visible {
    display: block;
  }

  &.danger {
    border-color: $dangerColor;
  }
}

.lineScope {
  display: none;
  position: absolute;
  height: 2px;
  background-color: $safeColor;
  pointer-events: none;
  z-index: $scope_z-index;

  &.visible {
    display: block;
  }

  &.danger {
    background-color: $dangerColor;
  }
}
