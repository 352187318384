.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  background-color: white;
  border-bottom: 1px solid #e6e7f2;
}

.buttonArea {
  display: flex;
  align-items: center;
}

.logo {
  margin: 18.64px 0 17.64px;
}
