@import 'tableThree';

.content {
  display: flex;
  flex: 1;
}

.display {
  flex: 1;
  display: block;
  font-size: 14px;
  line-height: 1.4;
  color: #000111;
  text-align: center;
  display: flex;
  flex-direction: column;
  @include cell(widthRate($longTermGoalPeriodWidth, $longTermGoalAllWidth));
}

.block {
  flex: 1;
}
