.container {
  padding: 40px 24px;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;

  min-width: 548px;
  max-width: 800px;

  /* white/01 */
  background: #ffffff;

  /* BlackShadow */
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
  border-radius: 4px;

  position: relative;
}

.closeButton {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 24px;
  height: 24px;
  text-align: center;

  * {
    fill: #7b7c88;
  }
}
