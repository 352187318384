.container {
  display: inline-flex;
  align-items: center;
  padding: 6px 4px 6px 7px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
}

.text {
  margin-left: 6px;
  font-size: 14px;
  line-height: 1.4;
  color: #0950b4;

  &.failed {
    color: red;
  }
}

@mixin show {
  opacity: 1;
  transform: translateY(0);
}

@mixin hide {
  opacity: 0;
  transform: translateY(18px);
}

.entering {
  @include hide;
}

.entered {
  @include show;
  transition: all 250ms;
}

.exiting {
  @include show;
}

.exited {
  @include hide;
  transition: all 250ms;
}

.unmounted {
}
