.container {
  margin-top: 24px;
  display: flex;
}

.left {
  width: 807px;
}

.rinen {
  font-size: 14px;
  line-height: 1.4;
  color: #000111;
  margin-top: 16px;
}

.tableContent {
  margin-top: 17px;
}

.row {
  display: flex;
  max-width: 807px;
  border-top: 2px solid #e6e7f2;
  border-left: 2px solid #e6e7f2;
  border-right: 2px solid #e6e7f2;
  padding-right: 20px;

  &:last-child {
    border-bottom: 2px solid #e6e7f2;
  }
}

.title {
  font-size: 14px;
  line-height: 1.4;
  color: #000111;
  padding: 22px 0 22px 24px;
  min-width: 231px;
  display: flex;
  align-items: center;
}

.boldTitle {
  @extend .title;
  font-size: 16px;
  font-weight: bold;
}

.content {
  font-size: 16px;
  line-height: 1.2;
  color: #000111;
  padding: 22.5px 0 22.5px 16px;
  display: flex;
  align-items: center;
}

.index {
  margin-left: 40px;

  .fixed {
    position: fixed;
  }

  .hidden {
    height: 0;
    overflow-y: hidden;
    visibility: hidden;
  }
}

.sectionTitle {
  margin-top: 40px;

  &:first-child {
    margin-top: 0;
  }
  position: relative;
  display: flex;
  font-size: 20px;
  line-height: 1.4;
  color: #000111;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -13px;
    width: 4px;
    height: 26px;
    background-color: #007efc;
    border-radius: 2px;
  }

  .sectionTitleContent {
    margin-left: 12px;
  }
}
