.wrapper {
  padding: 80px 0;
}

.container {
  margin: 0 auto;
  padding: 40px 24px;
  width: 548px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
}

.row {
  margin-top: 24px;
}

.form {
  padding-top: 24px;
}

.field {
  width: 280px;
  margin: 0 auto;
}

.hint {
  padding-top: 40px;
  font-size: 14px;
  line-height: 1.4;
  text-align: justify;
  color: #7b7c88;
}

.resetGuide {
  font-size: 14px;
  text-align: right;

  a {
    color: #007efc;

    &:hover {
      text-decoration: underline;
    }
  }
}
