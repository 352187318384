.row {
  padding: 5px 10px;
  border: 1px solid gray;
  border-radius: 4px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
}

.buttonArea {
  display: flex;
}

.editButtonArea {
  margin-right: 10px;
}

.userInfo {
  display: flex;
  align-items: center;
  width: 80%;
  overflow-wrap: break-word;
}

.nameArea {
  width: 30%;
}

.name {
  font-size: 18px;
  letter-spacing: 1.4px;
}

.email {
  margin-top: 6px;
  font-size: 14px;
  letter-spacing: 1.4px;
}

.adminLabel {
  background-color: #0950b4;
  border-radius: 4px;
  color: white;
  padding: 6px;
  margin-left: 30px;
}

.chatGptInfo {
  display: flex;
  align-items: center;
  //   padding: 3px;
  //   text-align: center;
  margin-left: 24px;
  font-size: 14px;
}

.limitInfo {
  font-size: 12px;
  margin-left: 8px;
  width: 110px;
}

.editButton {
  font-size: 12px;
  margin-left: 8px;
}
