.inputArea {
  display: flex;
  align-items: center;
}

.duration {
  margin-right: 5px;
}

.dash {
  margin: 0 3px;
}

.title {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: 0.05em;
}

.histories {
  display: flex;
  margin-top: 20px;
}

.loginHistory {
  padding: 7px;
}

.operationHistory {
  padding: 7px;
  margin-left: 12px;
}

.projectsMadeCount {
  padding: 7px;
  margin-left: 12px;
}

.careRecipientCreateCount {
  padding: 7px;
  margin-left: 12px;
}

.listContainer {
  width: 350px;
}

.row {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  line-height: 1.4;
  font-size: 16px;
  letter-spacing: 0.05em;
  margin-bottom: 6px;
  background-color: white;
}

.displayName {
  width: 300px;
}

.times {
  margin-left: 10px;
}

.searchButtonArea {
  margin-left: 10px;
}
