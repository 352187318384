.container {
  padding: 16px 120px 160px;
}

.link {
  font-size: 16px;
  line-height: 1.4;
  cursor: pointer;
  color: #0950b4;
}

.normalLink {
  font-size: 16px;
  line-height: 1.4;
  color: #000111;
}

.delimiter {
  margin: 0 4px;
  color: #000111;
}

.contentContainer {
  background-color: white;
  padding: 24px 24px 32px 24px;
  margin-top: 16px;
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
  border-radius: 4px;
}

.title {
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 0.05em;
  color: #000111;
}

.publishedDate {
  font-size: 16px;
  line-height: 1.4;
  color: #7b7c88;
  margin-top: 8px;
}

.content {
  font-size: 16px;
  line-height: 1.6;
  color: #000111;
  margin-top: 16px;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.buttonArea {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}
