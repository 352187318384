.container {
  display: flex;
  align-items: center;
}

.title {
  display: flex;
  align-items: center;
  padding: 10px 16px 10px 8px;
  font-size: 14px;
  line-height: 1.4;
  color: #7b7c88;
  border-right: 1px #b5b9c2 solid;
}
