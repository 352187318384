@import '../root';

.overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(61, 61, 63, 0.5);
  transition: all 150ms;
  z-index: $z-index_modal;
}
