.title {
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 0.05em;
  color: #000111;
  margin-bottom: 24px;
  text-align: center;
}

.sectionTitle {
  font-size: 20px;
  line-height: 1.3;
  letter-spacing: 0.05em;
  color: #7b7c88;
  margin-top: 16px;
}

.detail {
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.buttonArea {
  margin-top: 40px;
  text-align: center;
}
