.container {
  position: relative;
  padding-left: 14px;
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 0.05em;
  color: #000111;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -13px;
    width: 4px;
    height: 26px;
    background-color: #007efc;
    border-radius: 2px;
  }
}
