@import 'tableTwo';
@import '../tableTwoVariables';

.container {
  margin: 0 24px;
  width: 97%;
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
  border-radius: $borderRadius;
}

.tableHeader {
  z-index: $z-index_tableTwoHeader;
  position: fixed;
  top: 326px;
  width: 97%;
  display: flex;
  background-color: #f6f7f8;
  border-top-left-radius: $borderRadius;
  border-top-right-radius: $borderRadius;
}

.group {
  display: flex;
  flex-direction: column;
  border-right: $border;
  border-bottom: $border;
  flex-grow: 1;

  &:last-child {
    border-right: none;
  }
}

.header {
  border-bottom: $border;
}

.children {
  flex-grow: 1;
  display: flex;

  > * {
    border-right: $border;

    &:last-child {
      border-right: none;
    }
  }
}

.needText {
  width: widthRate($needTextWidth, $allWidth);
}

.goal {
  width: widthRate($goalWidth, $allWidth);
}

.longTermGoalText {
  width: widthRate($longTermGoalTextWidth, $goalWidth - $borderWidth);
}

.longTermGoalPeriod {
  width: widthRate($longTermGoalPeriodWidth, $goalWidth - $borderWidth);
}

.shortTermGoalText {
  width: widthRate($shortTermGoalTextWidth, $goalWidth - $borderWidth);
}

.shortTermGoalPeriod {
  width: widthRate(
    $shortTermGoalPeriodWidth - $borderWidth,
    $goalWidth - $borderWidth
  );
}

.assistanceContent {
  width: widthRate($assistanceContentWidth, $allWidth);
}

.supportServiceText {
  width: widthRate($supportServiceTextWidth, $assistanceContentWidth);
}

.supportServiceTypeInsuranceBenefit {
  width: widthRate(
    $supportServiceTypeInsuranceBenefitWidth,
    $assistanceContentWidth
  );
}

.supportServiceTypeText {
  width: widthRate($supportServiceTypeTextWidth, $assistanceContentWidth);
}

.supportServiceTypeEstablishment {
  width: widthRate(
    $supportServiceTypeEstablishmentWidth,
    $assistanceContentWidth
  );
}

.supportServiceTypeFrequency {
  width: widthRate($supportServiceTypeFrequencyWidth, $assistanceContentWidth);
}

.supportServiceTypePeriod {
  width: widthRate($supportServiceTypePeriodWidth, $assistanceContentWidth);
}

.content {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 11px 0 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;
  color: #000111;
  text-align: center;
}

// CSS Modulesによって別モジュールのクラス名にアクセスできない。そのためセレクタを駆使してボーダーを調整している。
// が、構造に左右されすぎるので本当は良くない。
.tableBody {
  padding-top: 295px;

  // last need
  > *:last-child {
    > * {
      border-bottom: none;

      // text
      &:first-child {
        border-bottom-left-radius: $borderRadius;
      }
    }

    // rest
    > *:last-child {
      // last long term goal
      > *:last-child {
        > * {
          border-bottom: none;
        }

        // rest
        > *:last-child {
          // last short term goal
          > *:last-child {
            > * {
              border-bottom: none;
            }

            // rest
            > *:last-child {
              // last support service
              > *:last-child {
                > * {
                  border-bottom: none;
                }

                // rest
                > *:last-child {
                  // last support service type
                  > *:last-child {
                    > * {
                      border-bottom: none;
                    }

                    // period
                    > *:last-child {
                      border-bottom-right-radius: $borderRadius;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
