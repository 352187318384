.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 4px;
}

.menu {
  margin-bottom: 17px;
  display: flex;
  align-items: center;
}

.lastEditedAt {
  margin-right: 16px;
  font-size: 13px;
  line-height: 1.2;
  color: #7b7c88;
}

.lastEditedAtRsLogin {
  font-size: 13px;
  line-height: 1.2;
  color: #7b7c88;
}
