// normal
$normalForegroundColor: #000111;

// active
$activeForegroundColor: #0950b4;

// disabled
$disabledForegroundColor: #d9ecff;

.button {
  transition: all 150ms;

  .focus {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
    width: 100%;
  }

  .leading,
  .trailing {
    display: inline-flex;
    align-items: center;
  }

  .leading {
    margin-right: 4px;
  }

  .trailing {
    margin-left: 4px;
  }

  .text {
    display: inline-block;
    font-size: 16px;
    line-height: 1;
  }

  &:not([disabled]) {
    color: $normalForegroundColor;

    .leading,
    .trailing {
      * {
        fill: $normalForegroundColor;
      }
    }

    &:hover,
    &:active {
      color: $activeForegroundColor;
      text-decoration: underline;

      .leading,
      .trailing {
        * {
          fill: $activeForegroundColor;
        }
      }
    }
  }

  &[disabled] {
    color: $disabledForegroundColor;

    .leading,
    .trailing {
      * {
        fill: $disabledForegroundColor;
      }
    }
  }
}
