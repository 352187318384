.title {
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 0.05em;
  color: #000111;
  margin-bottom: 24px;
  text-align: center;
}

.card {
  padding: 40px 24px;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;

  width: 548px;

  /* white/01 */
  background: #ffffff;

  /* BlackShadow */
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
  border-radius: 4px;
}

.contents {
  margin-left: auto;
  margin-right: auto;
  width: 280px;
}

.label {
  margin-bottom: 4px;
}

.field {
  margin-bottom: 20px;
}

.button {
  text-align: center;
  margin-bottom: 20px;
}

.error {
  color: #ff0000;
}
