.container {
  display: flex;
  background-color: #f6f7f8;
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
  border-radius: 4px;
  min-height: 100px;
  width: 99%;
}

.title {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 2px solid #e6e7f2;
  font-size: 18px;
}

.contents {
  flex: 8;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  height: 30px;
}

.periodLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 2px solid #e6e7f2;
  border-bottom: 2px solid #e6e7f2;
  flex: 2.5;
}

.establishNameLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 2px solid #e6e7f2;
  border-bottom: 2px solid #e6e7f2;
  flex: 2;
}

.serviceNameLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 2px solid #e6e7f2;
  border-bottom: 2px solid #e6e7f2;
  flex: 1;
}

.unitCountLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 2px solid #e6e7f2;
  border-bottom: 2px solid #e6e7f2;
  flex: 1;
}

.productCodeLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid #e6e7f2;
  flex: 1;
}
