.container {
  display: flex;
  justify-content: space-between;
  padding: 24px;
  cursor: pointer;
  align-items: center;

  &:hover .textArea > .question {
    color: #0950b4;
    text-decoration: underline;
  }
}

.textArea {
  display: flex;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0.05em;

  .prefix {
    color: #0950b4;
  }

  .question {
    margin-left: 10px;
    color: #000111;
  }
}
