.resultCount {
  margin-top: 20px;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0.05em;
  color: #000111;
}

.noneDiseases {
  text-align: center;
  margin-top: 40px;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0.05em;
  color: #000111;
}

.diseases {
  display: flex;
  flex-wrap: wrap;
  margin-top: -4px;

  > li {
    position: relative;
    width: calc(33% - 17px);
    margin-top: 28px;
    margin-right: 28px;
    padding: 12px;
    background-color: #f7fbff;
    border-radius: 4px;
    cursor: pointer;

    &:nth-of-type(3n) {
      margin-right: 0;
    }
  }
}

.diseaseName {
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 0.05em;
  color: #0950b4;
  margin-bottom: 14px;
}

.symptom {
  margin-left: 1.5em;
  margin-top: 8px;
  font-size: 14px;
  line-height: 1.4;
  color: #000111;
  list-style: disc;
}

.diseaseCategory {
  display: flex;
  align-items: center;
  margin-top: 18px;
  font-size: 13px;
  line-height: 1.2;
  color: #7b7c88;
  margin-bottom: 23px;

  svg {
    margin-right: 4px;

    * {
      fill: #7b7c88;
    }
  }
}

.detail {
  position: absolute;
  bottom: 12px;
  right: 12px;
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  line-height: 1.2;
  color: #000111;
  cursor: pointer;

  svg {
    margin-left: 4px;

    * {
      fill: #000111;
    }
  }
}

.clearKeyword {
  margin-left: 8px;
  font-size: 16px;
  line-height: 1.2;
  color: #0950b4;

  &:active,
  &:hover {
    text-decoration: underline;
  }
}
