@import 'tableTwo';

.container {
  @include container;
}

.textCell {
  @include cell(widthRate($supportServiceTextWidth, $supportServiceAllWidth));
}

.rest {
  @include rest(widthRate($supportServiceRestWidth, $supportServiceAllWidth));
}
