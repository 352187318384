.container {
  position: relative;
  width: 425px;
  padding: 24px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
}

.title {
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 0.05em;
  color: #000111;
}

.description {
  margin-top: 16px;
  font-size: 16px;
  line-height: 1.4;
  color: #000111;
}

.close {
  position: absolute;
  right: 12px;
  top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;

  * {
    fill: #7b7c88;
  }
}
