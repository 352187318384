@function focus-shadow($borderColor, $gapColor) {
  @return 0 0 0 2px $gapColor, 0 0 0 3px $borderColor;
}

// normal
$normalForegroundColor: #0950b4;

$normalBackgroundColor: white;

$normalBorderColor: white;

$normalShadow: 0px 2px 8px rgba(61, 61, 63, 0.12);

$normalFocusRing: focus-shadow($normalBorderColor, $normalBackgroundColor);

// active
$activeForegroundColor: #0950b4;

$activeBackgroundColor: white;

$activeBorderColor: #007efc;

$activeShadow: none;

$activeFocusRing: focus-shadow(#0950b4, $activeBackgroundColor);

// disabled
$disabledForegroundColor: #4ca5fd;

$disabledBackgroundColor: white;

$disabledBorderColor: white;

$disabledShadow: 0px 2px 8px rgba(61, 61, 63, 0.12);

.button {
  border-radius: 50px;
  transition: all 150ms;

  .focus {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-radius: 50px;
  }

  .leading,
  .trailing {
    display: inline-flex;
    align-items: center;
  }

  .leading {
    margin-right: 4px;
  }

  .trailing {
    margin-left: 4px;
  }

  .text {
    display: inline-block;
    padding: 11px 0;
    font-size: 16px;
    line-height: 1;
    letter-spacing: 0.05em;
  }

  &:not([disabled]) {
    background-color: $normalBackgroundColor;
    box-shadow: $normalShadow;
    color: $normalForegroundColor;

    .focus {
      border-color: $normalBorderColor;
    }

    .leading,
    .trailing {
      * {
        fill: $normalForegroundColor;
      }
    }

    &:hover,
    &:active {
      background-color: $activeBackgroundColor;
      box-shadow: $activeShadow;
      color: $activeForegroundColor;

      .focus {
        border-color: $activeBorderColor;
      }

      .leading,
      .trailing {
        * {
          fill: $activeForegroundColor;
        }
      }
    }

    &:focus-visible {
      .focus {
        box-shadow: $activeFocusRing;
      }

      &:hover,
      &:active {
        .focus {
          box-shadow: $activeFocusRing;
        }
      }
    }
  }

  &[disabled] {
    background-color: $disabledBackgroundColor;
    box-shadow: $normalShadow;
    color: $disabledForegroundColor;

    .focus {
      border-color: $disabledBorderColor;
    }

    .leading,
    .trailing {
      * {
        fill: $disabledForegroundColor;
      }
    }
  }
}
