.textArea {
  display: flex;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0.05em;

  .prefix {
    color: #0950b4;
  }

  .answer {
    margin-left: 10px;
    color: #000111;
  }
}
