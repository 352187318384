.pointer {
  cursor: pointer;
}

.prevMonth {
  margin-left: 10px;
  float: left;
  display: inline-block;
}

.nextMonth {
  margin-right: 10px;
  float: right;
  display: inline-block;
}

.yearMonth {
  display: inline-block;
  border: solid 1px;
  margin-left: 2px;
  margin-right: 2px;
  padding-left: 5px;
  padding-right: 5px;
}
