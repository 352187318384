.excelDownload {
  display: flex;
  align-items: center;

  svg {
    margin-right: 4px;

    * {
      fill: #7b7c88;
    }
  }

  span {
    font-size: 16px;
    line-height: 1;
    letter-spacing: 0.05em;
    color: #000111;
  }

  &:hover {
    color: #0950b4;
    text-decoration: underline;

    svg {
      * {
        fill: #0950b4;
      }
    }

    span {
      color: #0950b4;
    }
  }
}
