@mixin circle($color) {
  content: '';
  position: absolute;
  display: block;
  width: 10px;
  height: 10px;
  background-color: $color;
  border-radius: 5px;
  left: 2px;
  top: 2px;
}

.field {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  .input {
    position: absolute;
    appearance: none;
    opacity: 0;
  }

  .display {
    display: inline-block;
    position: relative;
    width: 18px;
    height: 18px;
    border-width: 2px;
    border-style: solid;
    border-radius: 9px;
  }

  .label {
    display: inline-block;
    margin-left: 7px;
    font-size: 16px;
    line-height: 1.2;
  }

  &:not(.checked):not(.disabled) {
    .display {
      background-color: white;
      border-color: #007efc;
    }

    .label {
      color: #000111;
    }

    &:hover,
    &:active {
      .display {
        background-color: #d9ecff;
        border-color: #0950b4;
      }

      .label {
        color: #0950b4;
      }
    }
  }

  &.checked:not(.disabled) {
    .display {
      background-color: white;
      border-color: #007efc;

      &::after {
        @include circle(#007efc);
      }
    }

    .label {
      color: #000111;
    }

    &:hover,
    &:active {
      .display {
        background-color: #d9ecff;
        border-color: #0950b4;

        &::after {
          @include circle(#0950b4);
        }
      }

      .label {
        color: #0950b4;
      }
    }
  }

  // TODO: disabled時の見た目は不十分
  &:not(.checked).disabled {
    .display {
      background-color: white;
      border-color: #d9ecff;
    }

    .label {
      color: #b5b9c2;
    }
  }

  &.checked.disabled {
    .display {
      background-color: white;
      border-color: #d9ecff;

      &::after {
        @include circle(#d9ecff);
      }
    }

    .label {
      color: #b5b9c2;
    }
  }
}
