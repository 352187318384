.tabs {
  display: inline-flex;
  border-radius: 50px;
  box-shadow: 2px 2px 10px rgba(110, 110, 160, 0.16);
}

.tab {
  background-color: white;
  color: #7b7c88;

  &:first-child {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }

  &:last-child {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }

  a {
    display: inline-block;
    padding: 13px 16px;
    width: 200px;
    font-size: 16px;
    line-height: 1.4;
    text-align: center;

    &:hover {
      color: #0950b4;
      text-decoration: underline;
    }
  }
}

.active {
  font-weight: bold;
  color: #0950b4;
}
