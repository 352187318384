@mixin button {
  padding: 3px 7px;
  background-color: white;
  border: 1px solid #0950b4;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.4;
  color: #0950b4;
}

.container {
  position: relative;
  width: 372px;
  padding: 24px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
}

.title {
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 0.05em;
  color: #000111;
}

.fieldContainer {
  margin-top: 16px;
}

.dateTitle {
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 1.4;
  color: #000111;
}

.fields {
  display: flex;
  justify-content: space-between;
}

.field {
  display: inline-flex;
  align-items: center;
}

.unit {
  margin-left: 4px;
  font-size: 16px;
  line-height: 2;
  color: #000111;
}

.endPeriodUpdaters {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.endPeriodUpdater {
  @include button;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }
}

.copyShortTermGoalPeriod {
  @include button;
  margin-top: 8px;

  &[disabled] {
    border-color: #d9ecff;
    color: #d9ecff;
  }
}

.invalidMessage {
  margin-top: 16px;
  color: red;
}

.buttonContainer {
  margin-top: 32px;
  text-align: center;
}

.close {
  position: absolute;
  right: 12px;
  top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;

  * {
    fill: #7b7c88;
  }
}
