.row {
  padding: 5px 10px;
  border: 1px solid gray;
  border-radius: 4px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
}

.registerButtonArea {
  margin-bottom: 10px;
}

.buttonArea {
  display: flex;
}

.editButtonArea {
  margin-right: 10px;
}

.officeName {
  font-size: 18px;
  letter-spacing: 1.4px;
}

.address {
  margin-top: 6px;
  font-size: 14px;
  letter-spacing: 1.4px;
}
