.field {
  border-radius: 4px;
  transition: all 150ms;

  .focus {
    display: inline-flex;
    align-items: center;
    padding: 0 7px;
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    border-color: #b5b9c2;
  }

  .danger {
    border-color: #ff0000 !important;
  }

  .leading,
  .trailing {
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
  }

  .leading {
    margin-right: 10px;
  }

  .trailing {
    margin-left: 10px;
  }

  .input {
    display: inline-block;
    width: 100%;
    flex-grow: 1;
    padding: 10px 0;
    font-size: 16px;
    line-height: 1.2;
    color: #000111;
    resize: vertical;
  }

  :not(textarea).input {
    height: 38px; /* 本来高さを指定するべきでないが、なんか収まらないのでとりあえず指定 */
  }

  &:not([disabled]) {
    background-color: white;

    .leading,
    .trailing {
      * {
        fill: #7b7c88;
      }
    }

    &:focus-within {
      .focus {
        box-shadow:
          0 0 0 2px white,
          0 0 0 3px #007efc;
      }
    }
  }

  &[disabled] {
    background-color: #e0e0e0;

    .focus {
    }

    .leading,
    .trailing {
      * {
        fill: #e0e0e0;
      }
    }
  }
}
