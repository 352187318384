.row {
  margin-top: 24px;
}

.error {
  color: #ff0000;
}

.form {
  width: 280px;
}

.button {
  text-align: center;
}
