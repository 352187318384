.row {
  display: flex;
  &:first-child {
    margin-bottom: 8px;
  }
}

.title {
  font-size: 16px;
  line-height: 1.4;
  color: #0950b4;
  width: 96px;
  margin-right: 24px;
}

.content {
  font-size: 16px;
  line-height: 1.4;
  color: #000111;
}
