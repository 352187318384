.field {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  .input {
    position: absolute;
    appearance: none;
    opacity: 0;
  }

  .display {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    border-width: 2px;
    border-style: solid;
    border-radius: 2px;
  }

  .label {
    display: inline-block;
    margin-left: 7px;
    font-size: 16px;
    line-height: 1.2;
  }

  &:not(.checked):not(.disabled) {
    .display {
      background-color: white;
      border-color: #007efc;

      svg {
        display: none;
      }
    }

    .label {
      color: #000111;
    }

    &:hover,
    &:active {
      .display {
        background-color: #d9ecff;
        border-color: #0950b4;
      }

      .label {
        color: #0950b4;
      }
    }
  }

  &.checked:not(.disabled) {
    .display {
      background-color: #007efc;
      border-color: #007efc;

      svg {
        display: inline-block;
      }
    }

    .label {
      color: #000111;
    }

    &:hover,
    &:active {
      .display {
        background-color: #0950b4;
        border-color: #0950b4;
      }

      .label {
        color: #0950b4;
      }
    }
  }

  // TODO: disabled時の見た目は不十分
  &:not(.checked).disabled {
    .display {
      background-color: white;
      border-color: #d9ecff;

      svg {
        display: none;
      }
    }

    .label {
      color: #b5b9c2;
    }
  }

  &.checked.disabled {
    .display {
      background-color: #d9ecff;
      border-color: #d9ecff;

      svg {
        display: inline-block;
      }
    }

    .label {
      color: #b5b9c2;
    }
  }
}
