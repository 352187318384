.container {
  padding: 16px 120px 160px;
}

.link {
  font-size: 16px;
  line-height: 1.4;
  color: #0950b4;
}

.normalLink {
  font-size: 16px;
  line-height: 1.4;
  color: #000111;
}

.delimiter {
  margin: 0 4px;
}

.pageTitle {
  margin-top: 24px;
  font-size: 24px;
  margin-bottom: 12px;
  line-height: 1.3;
  letter-spacing: 0.05em;
  color: #000111;
}

.listContainer {
  padding: 24px;
  background-color: white;
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
}

.row {
  padding: 16px 0;
  background-color: white;
  position: relative;
  border-bottom: solid 1px #e6e7f2;
  cursor: pointer;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.publishedDate {
  font-size: 14px;
  line-height: 1.4;
  color: #000111;
}

.title {
  font-size: 18px;
  line-height: 1.4;
  color: #0950b4;
  font-weight: bold;
  margin-top: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.nothingNotificationMessage {
  font-size: 20px;
  margin-bottom: 18px;
  line-height: 1.3;
  letter-spacing: 0.05em;
  color: #000111;
}

.pagerButtonArea {
  margin-top: 24px;
}
