.slick-slide > div {
  margin: 0 2px;
}

.slick-list {
  margin: 0 -2px;
}

.slick-track {
  float: left;
}
