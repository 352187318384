.button {
  display: flex;
  align-items: center;
  margin-left: 16px;
  padding: 8px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);

  &:hover > .disease {
    color: #0950b4;
    text-decoration: underline;
  }
}

.disease {
  font-size: 16px;
  line-height: 1.4;
  color: #0950b4;
}

.detail {
  margin-left: 8px;
  font-size: 11px;
  line-height: 1;
  letter-spacing: -0.01em;
  color: #000111;
}

.arrow {
  margin-left: 4px;
  display: inline-flex;
  align-items: center;

  svg * {
    fill: #000111;
  }
}
