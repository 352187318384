.container {
  position: relative;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sliderImage {
  height: 81px;
}

.nextButton {
  position: absolute;
  top: 10px;
  right: -5px;
}

.previousButton {
  position: absolute;
  top: 10px;
}
