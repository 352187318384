.container {
  position: relative;
  width: 18px;
  height: 18px;

  > * {
    position: absolute;
    left: -1px;
    top: -1px;
  }
}
