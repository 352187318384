@import '../../root';

.container {
  display: flex;
  flex-direction: column;
  width: 1060px;
  padding: 16px 24px 20px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);

  overflow-y: scroll;
  height: calc(100vh - 15px);
}

svg {
  margin-right: 4px;

  * {
    fill: #7b7c88;
  }
}

.waitingContainer {
  display: flex;
  flex-direction: column;
  width: 950px;
  padding: 16px 16px 20px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);

  overflow-y: scroll;
  height: calc(100vh - 15px);
  justify-content: center;
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.textArea {
  padding-top: 4px;
  padding-right: 8px;
  padding-left: 5px;
  padding-bottom: 8px;
}

.error {
  color: #ff0000;
  margin: 10px;
}

.prevAnswer {
  margin: 10px;
  max-height: 200px;
  min-height: 120px;
  overflow-y: auto;
}
f .checkBox {
  margin: 10px;
}

.button {
  margin-top: 18px;
  text-align: center;
}

.fileButton {
  color: black;
  cursor: pointer;
  border: 1px solid #b5b9c2;
  border-radius: 50px;
  padding-right: 0.5rem;
  width: 24rem;
  &::file-selector-button {
    background: linear-gradient(90deg, #0950b4 12.5%, #007efc 100%);
    color: white;
    border: none;
    cursor: pointer;
    border-right: 1px solid #b5b9c2;
    padding: 0.25rem 1rem;
    margin-right: 1rem;
    &:hover {
      background: #007efc;
    }
  }
}

.fileArea {
  display: flex;
  padding-left: 14px;
  align-items: center;
}

.clearButton {
  float: left;
  margin-left: 8px;
}

.closeButton {
  width: 20px;
  height: 20px;
  svg * {
    fill: #7b7c88;
  }
}

.closeArea {
  margin-left: auto;
}

.cautionMessage {
  margin-top: 8px;
  color: #ffaa00;
  font-weight: bolder;
  text-align: center;
}

.usableNumber {
  margin-top: 34.5px;
  text-align: center;
  font-size: 16px;
}

.warningMessage {
  margin-top: 8px;
  font-size: 110%;
  font-weight: bold;
  color: #ff0000;
  text-align: center;
}

.ratioMessage {
  font-size: 90%;
}

.contentTitle {
  margin-top: 16px;
  font-size: 24px;
  margin-left: 0;
  text-align: left;
  letter-spacing: 0.05em;
}
.contentSubTitle {
  margin-top: 16px;
  font-size: 16px;
  margin-bottom: 16px;

  margin-left: 0;
  text-align: left;

  line-height: 1.4;
  color: #000111;
}

.consultationTextArea {
  padding: 8px;
  width: 100%;
  height: 200px;
}

.consultationArea {
  display: flex;
  flex-flow: column;
  margin: 14px 6px 6px 12px;
  padding-left: 2px;
  width: 100%;

  .heading {
    font-size: 16.5px;
    margin-bottom: 10px;
  }
  .assignmentHeading {
    font-size: 16.5px;
    margin-top: 16px;
  }
}

.callHeading {
  margin: 0 0 0 auto;
  font-size: 16.5px;
}

.consultationContentArea {
  display: flex;
  flex-flow: column;
  margin-top: 8px;
  padding-left: 5px;
  width: 100%;
  height: 150px;

  .heading {
    margin-top: 8px;
    font-size: 16.5px;
  }
  .textArea {
    width: 100%;
    height: 150px;
  }
}

.consultationContentSubArea {
  display: flex;
  margin-top: 8px;

  .titleArea {
    margin-top: 8px;
    width: 1%;
    height: 112px;
    font-size: 16px;
  }
  .textArea {
    width: 99%;
    height: 100%;
  }
}

.consultationSubArea {
  display: flex;
  margin-bottom: 4px;

  .titleArea {
    margin-top: 8px;
    width: 10%;
    height: 112px;
    font-size: 16px;
  }
  .textArea {
    width: 90%;
    height: 100%;
  }
}

.infoArea {
  display: flex;
  font-size: 16.8px;
  label {
    display: inline-flex;
    align-items: center;
  }
}

.menuDisplayName {
  display: flex;
  align-items: center;
  padding-left: 50px;
  padding-right: 8px;
  svg * {
    fill: #000111;
  }

  span {
    margin-right: 4px;
    line-height: 1.4;
    color: #000111;
  }

  &:active,
  &:hover {
    color: #0950b4;
    text-decoration: underline;

    svg * {
      fill: #0950b4;
    }

    span {
      color: #0950b4;
    }
  }
}

.dropDownMenu {
  position: absolute;
  border: 1px solid #e6e7f2;
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
  background-color: #ffffff;
  border-radius: 4px;
  padding: 6px;
  width: 224px;
  z-index: $z-index_global_header;
}

.settings {
  color: #000111;
  cursor: pointer;
  font-size: 90%;

  &:not([disabled]) {
    &:hover,
    &:active,
    &:focus {
      color: #0950b4;
      text-decoration: underline;
    }
  }
}

.footerArea {
  margin-top: 32px;

  font-size: 16px;
  text-align: center;
}

.link {
  line-height: 1.4;
  color: #0950b4;
  &:hover,
  &:active,
  &:focus {
    color: #0950b4;
    text-decoration: underline;
  }
}

.row {
  display: flex;
  background-color: #f6f7f8;
  border-top: 1px solid #e6e7f2;
  border-left: 1px solid #e6e7f2;
  box-shadow: 4px 4px 5px rgba(48, 48, 49, 0.18);
}

.label {
  display: flex;
  flex-direction: column;
  width: 19%;
  padding: 24px 24px;
  font-size: 16.5px;
  line-height: 20px; // 行の高さを20pxにしないと.fieldと高さが一致しないので絶対値で指定
  color: #000111;
  border-right: 1px solid #e6e7f2;
}

.field {
  display: flex;
  width: 81%;
  background-color: white;
}

.integration {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 81%;
  background-color: white;
  padding: 8px;
}

.integrationButton {
  flex: 0 0 auto;
}

.summaryTextarea {
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 2px;
  font-size: 12px;
}

.importButton {
  font-size: 9px;
  margin: auto 0;
}

.headerArea {
  display: flex;
}

.titleArea {
  width: 60%;
}

.recorderResponse {
  width: 40%;
}

.selectValueArea {
  margin-top: 10px;
  text-align: center;

  .description {
    display: inline-box;
    font-size: 0.85rem;
  }

  .selectButtonArea {
    margin-top: 4px;
    display: flex;
    gap: 10px;
    .radio {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 15px;
      cursor: pointer;
    }

    .selected {
      border: none;
      background-color: rgba(0, 126, 252, 1);
      color: white;
    }

    span {
      margin-left: 8px;
    }
  }
}
