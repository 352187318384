.diseaseCategories {
  display: flex;
  flex-wrap: wrap;
  margin-top: -4px;

  > li.diseaseCategory {
    width: calc(33% - 17px);
    margin-top: 28px;
    margin-right: 28px;
    background-color: #f7fbff;
    border-radius: 4px;
    padding: 12px;

    &:nth-of-type(3n) {
      margin-right: 0;
    }
  }
}

.diseaseCategoryName {
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 0.05em;
  color: #0950b4;
  margin-bottom: 12px;

  svg {
    margin-right: 8px;

    * {
      fill: #007efc;
    }
  }
}

.diseaseName {
  margin-left: 1.5em;
  margin-top: 8px;
  font-size: 16px;
  line-height: 1.4;
  color: #000111;
  list-style: disc;

  a {
    &:hover {
      color: #0950b4;
      text-decoration: underline;
    }
  }
  &:first-child {
    margin-top: 0;
  }
}

.superVisor {
  width: calc(33% - 17px);
  padding-top: 156px;
  padding-left: 16px;

  .superVisorTitle {
    font-weight: bold;
    font-size: 16px;
    line-height: 1.4;
    color: #000111;
    margin-bottom: 8px;
  }
  .superVisorName {
    font-size: 14px;
    line-height: 1.4;
    color: #000111;

    &:last-child {
      margin-top: 8px;
    }
  }
}
