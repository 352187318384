.result {
  position: relative;
  padding-left: 14px;
  margin-bottom: 15px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -13px;
    width: 4px;
    height: 26px;
    background-color: #007efc;
    border-radius: 2px;
  }
}

.message {
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 0.05em;
  color: #000111;
}

.clearKeyword {
  margin-left: 8px;
  font-size: 16px;
  line-height: 1.2;
  color: #0950b4;

  &:active,
  &:hover {
    text-decoration: underline;
  }
}

.item {
  margin: 12px 0;

  &:last-child {
    margin-bottom: 0;
  }
}

.noResult {
  margin-top: 43px;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0.05em;
  color: #000111;
  text-align: center;
}
