@mixin decoratedBox($position: relative) {
  position: $position;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 4px;
    background-image: linear-gradient(90deg, #0950b4 12.5%, #007efc 100%);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
