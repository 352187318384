.form {
  height: 70px;
  display: flex;
}

.period {
  flex: 2.5;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 2px solid #e6e7f2;
}

.periodContent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
}

.establishName {
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 2px solid #e6e7f2;
}

.serviceName {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 2px solid #e6e7f2;
}

.unitCount {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 2px solid #e6e7f2;
}

.productCode {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  position: absolute;
  right: 15px;
  margin-top: 25px;

  &:last-child {
    margin-right: 0;
  }

  svg {
    vertical-align: bottom;
  }

  &:hover {
    .tooltip {
      display: inline-block;
    }
  }
}

.topButton {
  position: absolute;
  right: 15px;
  margin-top: 10px;

  &:last-child {
    margin-right: 0;
  }

  svg {
    vertical-align: bottom;
  }

  &:hover {
    .tooltip {
      display: inline-block;
    }
  }
}

.bottomButton {
  position: absolute;
  right: 15px;
  margin-top: 45px;

  &:last-child {
    margin-right: 0;
  }

  svg {
    vertical-align: bottom;
  }

  &:hover {
    .tooltip {
      display: inline-block;
    }
  }
}

.buttonAdd {
  &:hover {
    svg * {
      fill: #007efc;
    }
  }
  .tooltip {
    background-color: #007efc;
  }
}

.buttonRemove {
  .tooltip {
    background-color: #ff0000;
  }

  &:hover {
    svg * {
      fill: #ff0000;
    }
  }
}

.tooltip {
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, -4px);
  display: none;
  padding: 4px;
  border-radius: 4px;
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
  font-size: 14px;
  line-height: 1.4;
  color: white;
  white-space: nowrap;
}

.search {
  margin-left: 10px;
}
