.container {
  display: flex;
  margin-top: 16px;
}

.thumbnails {
  width: 448px;
  height: 350px;
  background-color: gray;
}

.info {
  margin-left: 24px;
}

.serviceType {
  font-size: 16px;
  line-height: 1.4;
  color: #000111;
  margin-top: 8px;
}

.jigyoshoName {
  font-size: 20px;
  line-height: 1.4;
  color: #000111;
  margin-top: 8px;
}

.basicInfo {
  margin-top: 17px;

  .row {
    border-bottom: 1px solid #e6e7f2;
    padding: 8px 0;
    display: flex;
    align-items: center;
    width: 671px;

    &:first-child {
      padding-top: 0;
    }
  }

  .title {
    font-size: 14px;
    line-height: 1.4;
    color: #7b7c88;
    min-width: 112px;
  }

  .content {
    font-size: 16px;
    line-height: 1.4;
    color: #000111;
    margin-left: 24px;
  }
}

.group {
  display: flex;
  justify-content: space-between;
}
