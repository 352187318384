// normal
$normalForegroundColor: white;

$normalBackground: linear-gradient(90deg, #0950b4 12.5%, #007efc 100%);

// active
$activeForegroundColor: white;

$activeBackgroundColor: #007efc;

// disabled
$disabledForegroundColor: white;

$disabledBackgroundColor: #d9ecff;

.button {
  border-radius: 50px;

  .focus {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    width: 100%;
    border-radius: 50px;
  }

  .leading,
  .trailing {
    display: inline-flex;
    align-items: center;
  }

  .leading {
    margin-right: 4px;
  }

  .trailing {
    margin-left: 4px;
  }

  .text {
    display: inline-block;
    padding: 12px 0;
    font-size: 16px;
    line-height: 1;
    letter-spacing: 0.05em;
  }

  &:not([disabled]) {
    background: $normalBackground;
    color: $normalForegroundColor;

    .leading,
    .trailing {
      * {
        fill: $normalForegroundColor;
      }
    }

    &:hover,
    &:active {
      background: $activeBackgroundColor;
      color: $activeForegroundColor;

      .leading,
      .trailing {
        * {
          fill: $activeForegroundColor;
        }
      }
    }
  }

  &[disabled] {
    background-color: $disabledBackgroundColor;
    color: $disabledForegroundColor;

    .leading,
    .trailing {
      * {
        fill: $disabledForegroundColor;
      }
    }
  }
}
