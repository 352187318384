.container {
  padding: 40px;
  overflow-y: scroll;
  height: calc(100vh - 15px);
  position: relative;

  /*macのデフォルト設定がスクロールバーを表示しないようになっているため、.confirmButtonで指定したボタン位置がずれる。
  常にスクロールバーを表示することで.confirmButtonのポジションを指定しやすくしている*/
  &::-webkit-scrollbar {
    width: 20px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: lightgray;
  }
}

.searchResult {
  display: flex;
  flex-wrap: wrap;
}

.confirmButton {
  position: fixed;
  bottom: 20px;
  /* スクロールバーの分、中央がずれてしまうので調整しています*/
  left: calc(50% - 5px);
  transform: translate(-50%, -50%);
}

.hidden {
  display: none;
}
