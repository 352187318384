@import 'tableTwo';

.container {
  @include container;
}

.insuranceBenefitCell {
  @include cell(
    widthRate(
      $supportServiceTypeInsuranceBenefitWidth,
      $supportServiceTypeAllWidth
    )
  );
}

.textCell {
  @include cell(
    widthRate($supportServiceTypeTextWidth, $supportServiceTypeAllWidth)
  );
}

.establishmentCell {
  @include cell(
    widthRate(
      $supportServiceTypeEstablishmentWidth,
      $supportServiceTypeAllWidth
    )
  );
}

.frequencyCell {
  @include cell(
    widthRate($supportServiceTypeFrequencyWidth, $supportServiceTypeAllWidth)
  );
}

.periodCell {
  @include cell(
    widthRate($supportServiceTypePeriodWidth, $supportServiceTypeAllWidth)
  );
  border-right: none;
}

.content {
  padding: 8px;
  width: 100%;
  height: 100%;
  position: relative;
}

.insuranceBenefit {
  display: inline-block;
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 1.4;
  color: #000111;
  text-align: center;

  &:hover {
    color: #0950b4;
  }
}
