.cell {
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.4;
}

.active {
  background-color: #007efc;
  color: #ffffff;
}

.inactive {
  background-color: #e6e7f2;
  color: #b5b9c2;
}
