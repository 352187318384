.container {
  padding: 16px 150px;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 18px;
}

.listContainer {
  padding: 24px 24px 16px 24px;
  background-color: white;
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
}

.pageTitle {
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 0.05em;
  color: #000111;
}

.pagerButtonArea {
  margin-top: 24px;
}
