.container {
  display: flex;
  align-items: baseline;
  margin-top: 5px;
}

.cell {
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.4;
  margin-right: 4px;

  &:last-child {
    margin-right: 0;
  }
}

.active {
  background-color: #007efc;
  color: #ffffff;
}

.inactive {
  background-color: #e6e7f2;
  color: #b5b9c2;
}
