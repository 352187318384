.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  position: relative;

  &:hover {
    .icons {
      visibility: visible;
    }
  }
}

.text {
  flex: 1 0 auto;
  padding: 8px 8px 0;
}

.icons {
  visibility: hidden;
  flex: 0 0 auto;
  margin: 4px 5px;
}
