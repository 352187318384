@import 'tableOne';

.container {
  @include container();
}

.row {
  display: flex;
  background-color: #f6f7f8;
  border-top: 1px solid #e6e7f2;
}

.label {
  display: flex;
  flex-direction: column;
  width: 20%;
  padding: 22px 24px;
  font-size: 14px;
  line-height: 20px; // 行の高さを20pxにしないと.fieldと高さが一致しないので絶対値で指定
  color: #000111;
}

.button {
  margin: auto 0;
}

.field {
  display: flex;
  align-items: center;
  width: 80%;
  padding: 12px 16px;
  background-color: white;
}

.iko {
  width: 100%;
}

.ikoRow {
  display: table;
  width: 100%;
  /*
  display: flex;
  border-top: 1px solid #E6E7F2;*/
}

.ikolabel {
  display: table-cell;
  width: 15%;
  position: relative;
}

.ikoField {
  display: table-cell;
  background-color: white;
  width: 85%;
}

.santeiRiyuField {
  align-items: center;
  width: 80%;
  padding: 12px 16px;
  background-color: white;
}

.sheet {
  flex-grow: 1;
}

.index {
  flex-shrink: 0;
  margin-left: 16px;

  .hidden {
    height: 0;
    overflow-y: hidden;
    visibility: hidden;
  }
}

.section {
  margin-bottom: 32px;
}

.card {
  margin-top: 16px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);

  &:first-child {
    margin-top: 0;
  }
}

.title {
  padding: 17px 24px;
  font-size: 16px;
  line-height: 1.4;
  font-weight: bold;
  color: #0950b4;
}

.labelText {
  position: relative;
  width: auto;
  padding: 22px 16px;
  font-size: 14px;
  line-height: 20px; // 行の高さを20pxにしないと.fieldと高さが一致しないので絶対値で指定
  color: #000111;
}

.labelTextPaddingNo {
  position: relative;
  width: auto;
  padding-top: 4px;
  font-size: 14px;
  line-height: 20px; // 行の高さを20pxにしないと.fieldと高さが一致しないので絶対値で指定
  color: #000111;
}

.saveIndicator {
  position: absolute;
  right: 4px;
  top: 16.5px;
}

.horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  > * {
    margin-right: 19px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.vertical {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > * {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.modifier {
  font-size: 16px;
  line-height: 1.2;
  color: #000111;
}

.leading {
  font-size: 14px;
  line-height: 1.2;
  margin-right: 10px;
}

.trailing {
  font-size: 14px;
  line-height: 1.2;
  margin-left: 10px;
}

.multiField {
  border-radius: 4px;
  transition: all 150ms;
  display: inline-block;
  width: 100%;

  .focus {
    display: inline-flex;
    align-items: center;
    padding: 0 7px;
    width: 100%;
    border: none;
  }

  .input {
    display: inline-block;
    width: 100%;
    flex-grow: 1;
    padding: 10px 0;
    font-size: 14px;
    line-height: 1.2;
    color: #000111;
    resize: none;
  }
}
