@import '../../components/decoratedBox';
@import '../../tabletwo/tableTwoVariables';

.container {
  @include decoratedBox(absolute);

  z-index: $z-index_tableTwoHeader + 1;
  left: calc(100% + 2px);
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 200px;
  width: 100%;
  padding: 16px 16px 20px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
}

.titleArea {
  width: 100%;
  display: flex;
  justify-content: center;
}

.title {
  font-size: 20px;
  line-height: 140%;
  letter-spacing: 0.05em;
  color: #0950b4;
  margin-bottom: 24px;
}

.button {
  width: 100%;
  text-align: center;
}
