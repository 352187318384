.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.zeroResultContainer {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.zeroResultText {
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0.05em;
  color: #000111;
}
