.container {
  display: inline-flex;
  align-items: center;
}

.current {
  font-size: 14px;
  line-height: 1.4;
  color: #000111;
}

.latestTag {
  margin-left: 8px;
  padding: 4px 6px;
  background-color: #007efc;
  border-radius: 4px;
  font-size: 13px;
  line-height: 1.2;
  color: white;
}

.switchButton {
  display: inline-flex;
  align-items: center;
  margin-left: 16px;

  svg * {
    fill: #000111;
  }

  span {
    margin-right: 4px;
    font-size: 14px;
    line-height: 1.4;
    color: #000111;
  }

  &:active,
  &:hover {
    svg * {
      fill: #0950b4;
    }

    span {
      color: #0950b4;
      text-decoration: underline;
    }
  }
}

@import '../../root';
.dropdownModeless {
  align-self: flex-end;
  z-index: $z-index_project_header + 1;
}
