.container {
  padding: 8px;
  width: 100%;
  height: 100%;
  position: relative;
}

.display {
  display: inline-block;
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 1.4;
  color: #000111;
  text-align: center;
  &:hover {
    .copyButton {
      visibility: visible;
    }
  }
}

.period {
  &:hover {
    color: #0950b4;
  }
}

.separator {
  margin: 5px 0;
}

.copyButtonArea {
  display: flex;
  justify-content: center;
  margin-top: 24px;

  &:hover {
    color: #0950b4;
    svg * {
      fill: #0950b4;
    }
  }

  .copyButton {
    visibility: hidden;
    display: flex;
    align-items: center;
    .text {
      margin-left: 4px;
    }
  }
}

// scope
.boxScope {
  display: none;
  position: absolute;
  border: 2px solid #007efc;
  pointer-events: none;
  z-index: 1;

  &.visible {
    display: block;
  }
}
