.container {
  margin: 0 auto;
  padding: 40px 0 160px;
  width: 80%;
  min-width: 1200px;
}

.title {
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 0.05em;
  color: #000111;
}

.jumpButtonArea {
  margin-top: 24px;
  display: flex;
  justify-content: flex-start;
}

.mainArea {
  margin-top: 12px;
  /* カテゴリ名が15pxのpaddingを持っているため、スクロールボタンとの距離27pxを実現するため12pxとする */
}

.link {
  color: #000111;

  &:hover,
  &:active,
  &:focus {
    color: #0950b4;
    text-decoration: underline;
  }
}

.backToTopButtonArea {
  text-align: center;
  margin-top: 40px;
}
