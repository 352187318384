.container {
  display: flex;
  align-items: center;
  margin-left: 40px;
}

.containerRsLogin {
  display: flex;
  align-items: center;
}

.name {
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 0.05em;
  color: #000111;
}

.phonetic {
  margin-left: 12px;
  font-size: 14px;
  line-height: 1.4;
  color: #7b7c88;
}

.responsibleUser {
  margin-left: 16px;
  font-size: 14px;
  line-height: 1.4;
  color: #000111;
}

.editButton {
  display: inline-flex;
  align-items: center;
  margin-left: 16px;

  svg * {
    fill: #7b7c88;
  }

  span {
    margin-left: 4px;
    font-size: 14px;
    line-height: 1.4;
    color: #000111;
  }

  &:active,
  &:hover {
    text-decoration: underline;
    svg * {
      fill: #0950b4;
    }

    span {
      color: #0950b4;
    }
  }
}
