.icon {
  margin-left: 15px;
  cursor: pointer;

  &:hover svg > circle {
    fill: #f7fbff;
  }

  &:hover svg > path {
    fill: #007efc;
  }
}
