.container {
  display: flex;
  padding: 8px 16px;
  background-color: white;
  margin-bottom: 18px;
  border: 1px solid #b5b9c2;
  border-radius: 4px;
}

.listContainer {
  margin-left: 24px;
  overflow: hidden;
}

.row {
  display: flex;
  align-items: center;
  margin-top: 8px;

  &:first-child {
    margin-top: 0;
  }
}

.title {
  color: #000111;
  font-size: 16px;
  line-height: 1.4;
  white-space: nowrap;
}

.publishedDate {
  color: #7b7c88;
  font-size: 14px;
  line-height: 1.4;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.notification {
  color: #000111;
  font-size: 16px;
  line-height: 1.4;
  margin-left: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
