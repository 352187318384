.index {
  margin-bottom: 16px;
}

.indexLink {
  position: relative;
  padding-right: 17px;
  font-size: 13px;
  line-height: 1.2;
  color: #000111;

  &::after {
    content: '';
    position: absolute;
    right: 8px;
    top: 50%;
    margin-top: -8px;
    width: 1px;
    height: 16px;
    background-color: #000111;
  }

  &:last-child {
    padding-right: 0;

    &::after {
      content: none;
    }
  }

  &:hover {
    color: #0950b4;
    text-decoration: underline;
  }
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  padding: 0 8px 0 24px;
}

// font

.name,
.phonetic,
.responsibleUser,
.deactivated,
.lastEditedAt {
  font-size: 13px;
  line-height: 1.2;
  color: #000111;
}

// width

.name {
  flex: 1 0 154px;
}

.phonetic {
  flex: 1 0 184px;
}

.responsibleUser {
  flex: 1 0 109px;
}

.deactivated {
  flex: 1 0 519px;
}

.lastEditedAt {
  flex: 1 0 178px;
}

.contextMenu {
  flex: 0 0 24px;
}

// padding

.name,
.phonetic,
.responsibleUser,
.deactivated,
.lastEditedAt {
  padding-right: 12px;
}
