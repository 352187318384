.container {
  margin: 0 auto;
  padding: 40px 0;
  width: 80%;
  min-width: 1200px;
}

.title {
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 0.05em;
  color: #000111;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0 8px;
}

.form {
  display: flex;
  align-items: center;
}
