.container {
  padding: 146px 0;
  text-align: center;
}

.message {
  color: #000111;
  font-size: 20px;
  letter-spacing: 0.05em;
  line-height: 140%;
  margin-bottom: 40px;
}
