.dialog {
  width: 160px;
  padding: 16px 16px 0 16px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
}

.button {
  color: #000111;
  margin-bottom: 24px;
}

.alertButton {
  color: red;
  padding-bottom: 16px;
  &:disabled {
    color: #ffc7c9;
    position: relative;
    &::after {
      position: absolute;
      opacity: 0;
      content: attr(data-tooltip);
      display: block;
      padding: 6px 10px;
      background: #000111;
      color: #fff;
      border-radius: 2px;
      font-size: 13px;
    }
    &:hover {
      cursor: not-allowed;
      &::after {
        top: 25px;
        opacity: 1;
      }
    }
  }
}
