.container {
  width: 515px;
  padding: 24px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
  position: relative;
}

.title {
  font-size: 24px;
  line-height: 1.4;
  letter-spacing: 0.05em;
  color: #000111;
}

.description {
  margin-top: 16px;
  font-size: 16px;
  line-height: 1.4;
  color: #000111;
}

.corporationName {
  font-size: 18px;
  line-height: 1.4;
  color: #000111;
  margin-top: 16px;
}

.buttonArea {
  margin-top: 16px;
}

.dangerButtonArea {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.cancelButtonArea {
  display: flex;
  justify-content: center;
}

.closeIcon {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 24px;
  height: 24px;
  text-align: center;

  * {
    fill: #7b7c88;
  }
}

.errorMessage {
  color: red;
}
