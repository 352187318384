.container {
  display: flex;
  align-items: center;
}

.content {
  font-size: 14px;
  line-height: 1.4;
  color: #000111;
}
