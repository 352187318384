@import 'tableTwo';

.container {
  @include container();
}

.textCell {
  @include cell(widthRate($shortTermGoalTextWidth, $shortTermGoalAllWidth));
}

.periodCell {
  @include cell(widthRate($shortTermGoalPeriodWidth, $shortTermGoalAllWidth));
}

.rest {
  @include rest(widthRate($shortTermGoalRestWidth, $shortTermGoalAllWidth));
}
