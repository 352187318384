.card {
  padding: 40px 0;
  width: 548px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
}

.title {
  text-align: center;
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 0.05em;
  color: #000111;
}

.content {
  padding: 0 134px;
}

.row {
  margin-top: 24px;
}
