@import '../../components/decoratedBox';

.container {
  padding: 20px 24px;

  position: relative;
  width: calc(100vw - 200px);
  height: calc(100vh - 15px);
  overflow-y: auto;
  background-color: white;
  border-radius: 8px;
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
}

.listDialogButton {
  display: flex;
  align-items: center;

  svg * {
    fill: #000111;
  }

  span {
    margin-left: 4px;
    font-size: 14px;
    line-height: 1.4;
    color: #000111;
  }

  &:hover {
    svg * {
      fill: #0950b4;
    }
    span {
      color: #0950b4;
      text-decoration: underline;
    }
  }
}

.title {
  display: flex;
  align-items: center;
  margin-top: 28px;
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 0.05em;
  color: #0950b4;
}

.diseaseCategory {
  display: flex;
  align-items: center;
  margin-left: 8px;

  svg * {
    fill: #000111;
  }

  span {
    margin-left: 4px;
    font-size: 14px;
    line-height: 1.4;
    color: #000111;
  }
}

.columns {
  display: flex;
}

.content {
  flex-grow: 1;
}

.index {
  flex-shrink: 0;
  margin: 32px 16px 0 32px;

  .fixed {
    position: fixed;
  }

  .hidden {
    height: 0;
    overflow-y: hidden;
    visibility: hidden;
  }
}

.itemName {
  margin-top: 32px;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0.05em;
  color: #000111;
}

.subItemName {
  margin-top: 16px;
  font-size: 16px;
  line-height: 1.4;
  font-weight: bold;
  color: #000111;
}

.itemContents {
  list-style: disc;

  > li {
    font-size: 14px;
    line-height: 1.4;
    color: #000111;
    margin: 16px 0 0 1.2rem;
  }
}

.footer {
  margin: 40px auto 60px;
  text-align: center;
}

.closeButton {
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  * {
    fill: #7b7c88;
  }
}
