.container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.tabs {
  display: inline-flex;
  border-radius: 4px;
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
  margin-top: 16px;
}

.tab {
  display: flex;
  background-color: white;
  color: #7b7c88;
  padding: 12px 45px;
  cursor: pointer;
  align-items: center;

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.active {
  font-weight: bold;
  color: #0950b4;
}

.searchCount {
  background-color: #f7fbff;
  color: #0950b4;
  font-size: 14px;
  line-height: 1.4;
  border-radius: 4px;
  padding: 2px 4px;
  margin-left: 8px;
}
