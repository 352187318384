.button {
  font-size: 16px;
  line-height: 1.4;
  color: #000111;

  &:not([disabled]) {
    &:hover,
    &:active,
    &:focus {
      color: #0950b4;
      text-decoration: underline;
    }
  }
}
