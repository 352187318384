.container {
  position: relative;
  width: 100%;

  svg {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;

    * {
      fill: #b5b9c2;
    }
  }
}
