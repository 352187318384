.container {
  padding: 40px;
  position: relative;
  width: calc(100vw - 200px);
  height: calc(100vh - 15px);
  overflow-y: auto;
  background-color: white;
  border-radius: 8px;
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
}

.closeButton {
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  * {
    fill: #7b7c88;
  }
}

.title {
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 0.05em;
  color: #0950b4;
}

.form {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.searchButton {
  display: inline-flex;
  align-items: center;

  svg * {
    fill: #7b7c88;
  }
}
