.container {
  display: flex;
  align-items: center;
  padding: 17px 8px 17px 24px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
  transition: all 150ms;

  &:hover,
  &:active {
    background-color: #f6f8fa;
  }

  &:hover .name {
    color: #0950b4;
    text-decoration: underline;
  }
}

.name {
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0.05em;
  color: #000111;
  padding-right: 12px;
  flex: 1 0 154px;
  word-break: break-all;
}

.phonetic {
  font-size: 14px;
  line-height: 1.4;
  color: #7b7c88;
  padding-right: 12px;
  flex: 1 0 184px;
}

.responsibleUser {
  font-size: 14px;
  line-height: 1.4;
  color: #7b7c88;
  padding-right: 12px;
  flex: 1 0 109px;
}

.deactivated {
  padding-right: 12px;
  flex: 1 0 519px;
}

.lastEditedAt {
  font-size: 14px;
  line-height: 1.4;
  color: #7b7c88;
  padding-right: 12px;
  flex: 1 0 178px;
}

.contextMenu {
  flex: 0 0 24px;
}

.settingDialogOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 120%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 1;
}

.settingDialogPositionReference {
  position: relative;
}

.settingDialog {
  position: absolute;
  top: 90%;
  right: 1%;
  z-index: 1;
}

.deactivatedTag {
  display: inline-block;
  padding: 4px 6px;
  background-color: #7b7c88;
  border-radius: 4px;
  font-size: 13px;
  line-height: 1.2;
  font-weight: bold;
  color: white;
}

.contextMenuLink {
  display: inline-block;

  svg {
    vertical-align: bottom;

    * {
      fill: #7b7c88;
    }
  }
}
