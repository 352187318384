@mixin button {
  padding: 3px 7px;
  background-color: white;
  border: 1px solid #0950b4;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.4;
  color: #0950b4;
}

.container {
  padding: 40px 24px;
  margin: auto;

  width: 500px;
  height: 100%;
  max-height: 900px;

  /* white/01 */
  background: #ffffff;

  /* BlackShadow */
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
  border-radius: 4px;

  position: relative;

  overflow-y: auto;
}

.contents {
  display: flex;
  flex-direction: column;
  margin: 10px 20px;
}

.title {
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 0.05em;
  color: #000111;
}

.dateTitle {
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 1.4;
  color: #000111;
}

.fields {
  display: flex;
}

.field {
  display: inline-flex;
  align-items: center;
}

.unit {
  margin-left: 4px;
  font-size: 16px;
  line-height: 2;
  color: #000111;
}

.endPeriodUpdaters {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.endPeriodUpdater {
  @include button;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }
}

.copyShortTermGoalPeriod {
  @include button;
  margin-top: 8px;

  &[disabled] {
    border-color: #d9ecff;
    color: #d9ecff;
  }
}

.invalidMessage {
  margin-top: 16px;
  color: red;
}

.buttonContainer {
  display: flex;
  justify-content: space-around;
  margin-top: 32px;
  text-align: center;
}

.close {
  position: absolute;
  right: 12px;
  top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;

  * {
    fill: #7b7c88;
  }
}

.dayOfWeekSelect {
  display: flex;
}

.dayOfWeeks {
  flex: 1;
}

.dayOfWeek {
  margin-bottom: 5px;
}

.fieldContainer {
  margin-top: 16px;
}

.search {
  margin-left: 10px;
}

.textArea {
  border-radius: 4px;
  transition: all 150ms;
  display: inline-block;
  width: 100%;

  .focus {
    display: inline-flex;
    align-items: center;
    padding: 0 7px;
    width: 100%;
    border: none;
  }

  .input {
    display: inline-block;
    width: 100%;
    flex-grow: 1;
    padding: 10px 0;
    font-size: 14px;
    line-height: 1.2;
    color: #000111;
    resize: none;
  }
}

.errorMessage {
  color: red;
}
