.button {
  font-size: 14px;
  line-height: 1.4;
  color: #000111;
  margin-right: 18px;

  &:hover {
    color: #0950b4;
    text-decoration: underline;
  }
}
