.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 56px;
}

.input {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 1.4;
  color: #000111;
  resize: none;
  overflow: hidden;

  &:focus {
    padding: 7px;
    border: 1px solid #b5b9c2;
    border-radius: 4px;
    box-shadow:
      0 0 0 2px white,
      0 0 0 3px #007efc;
  }

  &:not(:focus):hover {
    color: #0950b4;
    cursor: pointer;
  }
}

.content {
  display: block;
  width: 100%;
  height: 100%;
  visibility: hidden;
  font-size: 14px;
  line-height: 1.4;
  overflow: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.wrapper:focus-within .content {
  padding: 8px;
}
