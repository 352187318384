@import 'tableTwo';

.container {
  @include container();
}

.textCell {
  @include cell(widthRate($longTermGoalTextWidth, $longTermGoalAllWidth));
}

.periodCell {
  @include cell(widthRate($longTermGoalPeriodWidth, $longTermGoalAllWidth));
}

.rest {
  @include rest(widthRate($longTermGoalRestWidth, $longTermGoalAllWidth));
}
