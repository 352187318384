.container {
  position: absolute;
  right: 0px;
  top: 16px;
  max-height: calc((16px * 1.2 + 24px) * 5 + 8px);
  padding: 4px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
  overflow-y: scroll;
}

.link {
  display: block;
  padding: 12px;
  font-size: 16px;
  line-height: 1.2;
  color: #000111;
  white-space: nowrap;

  &.selected {
    color: #0950b4;
  }

  &:active,
  &:hover {
    color: #0950b4;
    text-decoration: underline;
  }
}
