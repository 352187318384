@function focus-shadow($borderColor, $gapColor) {
  @return 0 0 0 2px $gapColor, 0 0 0 3px $borderColor;
}

// normal
$normalForegroundColor: white;

$normalBackgroundColor: red;

$normalBorderColor: red;

$normalShadow: none;

$normalFocusRing: focus-shadow($normalBorderColor, $normalBackgroundColor);

// active
$activeForegroundColor: white;

$activeBackgroundColor: #cd131c;

$activeBorderColor: #cd131c;

$activeShadow: none;

$activeFocusRing: focus-shadow($activeBorderColor, $activeBackgroundColor);

// disabled
$disabledForegroundColor: white;

$disabledBackgroundColor: #ffc7c9;

$disabledBorderColor: #ffc7c9;

$disabledShadow: none;

.button {
  border-radius: 50px;
  transition: all 150ms;

  .focus {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-radius: 50px;
  }

  .leading,
  .trailing {
    display: inline-flex;
    align-items: center;
  }

  .leading {
    margin-right: 4px;
  }

  .trailing {
    margin-left: 4px;
  }

  .text {
    display: inline-block;
    padding: 11px 0;
    font-size: 16px;
    line-height: 1;
  }

  &:not([disabled]) {
    background-color: $normalBackgroundColor;
    box-shadow: $normalShadow;
    color: $normalForegroundColor;

    .focus {
      border-color: $normalBorderColor;
    }

    .leading,
    .trailing {
      * {
        fill: $normalForegroundColor;
      }
    }

    &:hover,
    &:active {
      background-color: $activeBackgroundColor;
      box-shadow: $activeShadow;
      color: $activeForegroundColor;

      .focus {
        border-color: $activeBorderColor;
      }

      .leading,
      .trailing {
        * {
          fill: $activeForegroundColor;
        }
      }
    }

    &:focus-visible {
      .focus {
        box-shadow: $normalFocusRing;
      }

      &:hover,
      &:active {
        .focus {
          box-shadow: $activeFocusRing;
        }
      }
    }
  }

  &[disabled] {
    background-color: $disabledBackgroundColor;
    box-shadow: $disabledShadow;
    color: $disabledForegroundColor;

    .focus {
      border-color: $disabledBorderColor;
    }

    .leading,
    .trailing {
      * {
        fill: $disabledForegroundColor;
      }
    }
  }
}
