.container {
  position: relative;
  width: calc(100vw - 100px);
  background-color: white;
  border-radius: 4px;
  min-width: 950px;
}

.closeButton {
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 1;

  * {
    fill: #7b7c88;
  }
}
