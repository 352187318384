.syllabaryColumnItem {
  margin: 15px 0;

  &:first-child {
    margin-top: 0;
  }
}

.item {
  margin: 12px 0;

  &:last-child {
    margin-bottom: 0;
  }
}
