@import 'tableThree';
@import '../tableThreeVariables';

.container {
  display: flex;
  background-color: #f6f7f8;
  height: 600px;
  margin-bottom: 50px;
  box-shadow: 4px 4px 20px rgba(61, 61, 63, 0.08);
  border-radius: $borderRadius;
}

.division {
  flex: 2;
  display: flex;
  border-right: $border;
  border-bottom: $border;
}

.activity {
  flex: 3;
  display: flex;
}

.timeDivision {
  flex: 2;
  display: flex;
  flex-direction: column;
  border-right: $border;
  height: 100%;
  font-size: 16px;
  color: #000111;
}

.midnight {
  flex: 4;
  border-bottom: $border;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.earlyMorning {
  flex: 3;
  border-bottom: $border;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.morning {
  flex: 5;
  border-bottom: $border;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.afternoon {
  flex: 7;
  border-bottom: $border;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.night {
  flex: 3;
  border-bottom: $border;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.midnight2 {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.time {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: 16px;
  color: #000111;
}

.timeContents {
  flex: 1;
  border-bottom: $border;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.timeContentsLast {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.schedule {
  flex: 7;
  display: flex;
}
