@import '../root';

.dropDownMenu {
  position: absolute;
  top: 55px;
  right: 20px;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 16px;
  width: 144px;
  height: 94px;
  z-index: $z-index_global_header;
}

.settings {
  margin-bottom: 24px;
  color: #000111;
  cursor: pointer;

  &:not([disabled]) {
    &:hover,
    &:active,
    &:focus {
      color: #0950b4;
      text-decoration: underline;
    }
  }
}

.displayName {
  display: flex;
  align-items: center;

  svg * {
    fill: #000111;
  }

  span {
    margin-right: 4px;
    font-size: 14px;
    line-height: 1.4;
    color: #000111;
  }

  &:active,
  &:hover {
    color: #0950b4;
    text-decoration: underline;

    svg * {
      fill: #0950b4;
    }

    span {
      color: #0950b4;
    }
  }
}
